body {
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-size: 14px;
  color: #343a40;
}

::-webkit-scrollbar {
  width: 8px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: #cccccc; /* 스크롤바의 색상 */

  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.5); /*스크롤바 뒷 배경 색상*/
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bg-red {
  background-color: #c81f21;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.form-control,
.card,
.btn {
  border-radius: 0;
}

.form-control::placeholder {
  font-size: 12px;
}

.bm-subtitle {
  font-weight: bold;
  text-align: center;
}

.profile-button {
  font-weight: 900;
  width: 100%;
  max-width: 200px;
  height: 35px;
}

/* vs-title */
.vs-title {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.18;
  color: #2e2f36;
  margin: 0 0 5px 0;
  line-height: 1;
}

.vs-subtitle {
  color: #888;
  font-weight: 400;
  line-height: 1.47;
  -webkit-letter-spacing: -1.7px;
  -moz-letter-spacing: -1.7px;
  -ms-letter-spacing: -1.7px;
  letter-spacing: -1.7px;
  font-size: 15px;
}

@media (max-width: 768px) {
  vs-title {
    font-size: 20px;
  }

  .vs-subtitle {
    color: #888;
    font-size: 15px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    right: -200px;
  }
  100% {
    opacity: 1;
    right: -150px;
  }
}

.btn-login {
  background: #7444ff;
  border: 0;
}

.btn-login:hover {
  background: #828282;
  border: 0;
}
